import request from "./libs/rquest";
import i18n from "./libs/i18n";
import date from "./libs/date";
import file from "./libs/file";
import decimal from "./libs/decimal";
import KtButton from "./components/ktButton/KtButton.vue";

export default {
    request,
    i18n,
    file,
    date,
    decimal,
    install(app: any): void {
        // 注册全局组件
        app.component('kt-button', KtButton)
        app.config.globalProperties.$kt = this;
    }
}