/**
 * 设置自身信息
 */
let setSelfInfo = (selfInfo: any) => {
  localStorage.setItem("selfInfo", JSON.stringify(selfInfo));
};

/**
 * 获取自身信息
 */
let getSelfInfo = () => {
  let info = localStorage.getItem("selfInfo");
  if (info == null) {
    return null;
  }
  info = info + "";
  return JSON.parse(info);
};

/**
 * 判断是否拥有某个权限
 * 权限示例：{
 *      "permissionCodes": [
 *             "goodsProcureOrder",
 *             "goodsProcureOrder$view",
 *             "goodsProcureOrder$draft",
 *             "goodsProcureOrder$revoked",
 *             "goodsProcureRetutnOrder",
 *             "goodsProcureRetutnOrder$view",
 *             "goodsProcureRetutnOrder$draft",
 *             "goodsProcureRetutnOrder$revoked",
 *             "goodsProcureRetutnOrder$save"
 *         ]
 * }
 */
let isHasPermission = (permissionCode: any) => {
  let selfInfo = getSelfInfo();
  // console.log("selfInfo", selfInfo);
  // 查看是否有admin角色
  if (selfInfo && selfInfo.roleCodes) {
    let roles = selfInfo.roleCodes;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == "admin") {
        return true;
      }
    }
  }

  if (selfInfo == null) {
    return false;
  }
  let permissionCodes = selfInfo.permissionCodes;
  if (permissionCodes == null) {
    return false;
  }
  return permissionCodes.indexOf(permissionCode) > -1;
};

let isSuperAdmin = () => {
  let selfInfo = getSelfInfo();
  if (selfInfo == null) {
    return false;
  }
  let roleCodes = selfInfo.roleCodes;
  if (roleCodes == null) {
    return false;
  }
  return roleCodes.indexOf("superAdmin") > -1;
};

export default {
  setSelfInfo,
  getSelfInfo,
  isHasPermission,
  isSuperAdmin,
};
