// 日期格式化
const format = (date: any, fmt: string) => {
    if (!date) {
        // 如果没有传入时间，返回空字符串
        return '';
    }
        date = new Date(date);
    // 如果传入的是时间戳，转换成时间对象
    const o: any = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    // 星期
    const week: any = {
        0: '/u65e5',
        1: '/u4e00',
        2: '/u4e8c',
        3: '/u4e09',
        4: '/u56db',
        5: '/u4e94',
        6: '/u516d'
    };
    // 年份
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    // 星期
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') +
                week[date.getDay() + '']
        );
    }
    // 其他
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            );
        }
    }
    return fmt;
};

// 获取当前时间的时区
const getOffset = () => {
    const offset = new Date().getTimezoneOffset();
    return -offset;
};

// gmt时间转换成本地时间
const gmt2Local = (gmt: any,formatStr: string) => {
    const offset = getOffset();
    const local = new Date(new Date(gmt).getTime() + offset * 60 * 1000);
    if(formatStr!=null){
        return format(local,formatStr);
    }
    return local;
};

// 本地时间转换成gmt时间
const local2Gmt = (local: any) => {
    const offset = getOffset();
    const gmt = new Date(new Date(local).getTime() - offset * 60 * 1000);
    return gmt;
};

export default {
    format,
    getOffset,
    gmt2Local,
    local2Gmt
};