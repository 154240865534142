import axios, { AxiosRequestConfig } from "axios";
// 引入配置文件
import requstConfig from "./request.config";

// 获取语言编码
const getLanguageCode = () => {
  const loanguageCode =
    localStorage.getItem("languageCode") || navigator.language || "en_US";
  return loanguageCode.replace("-", "_");
};

// 设置语言编码
const setLanguageCode = (languageCode: string) => {
  localStorage.setItem("languageCode", languageCode);
};

// 获取地址
const getRootAddress = () => {
  const rootAddress =
    localStorage.getItem("rootAddress") || requstConfig.rootAddress;
  return rootAddress;
};

// 获取上传路径
const getUploadAddress = () => {
  const uploadAddress =
    localStorage.getItem("uploadAddress") || requstConfig.uploadAddress;
  return uploadAddress;
};

// 创建axios实例
const requestAxios = axios.create({
  baseURL: requstConfig.rootAddress,
  timeout: 300000, // 请求超时时间
  headers: {
    token: localStorage.getItem(requstConfig.tokenStroageKey),
    languageCode: getLanguageCode(),
  },
});

// request拦截器
requestAxios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 请求超时
    if (
      error.code === "ECONNABORTED" &&
      error.message.indexOf("timeout") !== -1
    ) {
      // console.log("timeout请求超时");
      return {
        data: {
          successs: false,
          errMsg: "请求超时",
        },
      };
    }
  }
);

// uuid可以为空,为空时不记录请求次数
const request = (param: any) => {
  // uuid = uuid || Math.random().toString(36).substr(2);
  // if (uuid) {
  //   // 将uuid存入localStorage，并记录请求次数
  //   var requestNumber: any = localStorage.getItem("requestNumberBy:" + uuid);
  //   if (requestNumber) {
  //     requestNumber = parseInt(requestNumber) - -1;
  //   } else {
  //     requestNumber = 1;
  //   }
  //   // localStorage.setItem("requestNumberBy:" + uuid, requestNumber + "");
  // }

  let result = requestAxios({
    ...param,
    headers: {
      token: localStorage.getItem(requstConfig.tokenStroageKey),
      languageCode: getLanguageCode(),
      contentType: "application/json;charset=UTF-8",
      ...param.headers,
    },
  });

  return new Promise((resolve, reject) => {
    result
      .then((res) => {
        resolve(res);
        return false;
      })
      .catch((err) => {
        console.log(JSON.stringify(err.message), "---err---");
        // 如果404和503都断网重连
        if (
          err.message.indexOf("404") != -1 ||
          err.message.indexOf("503") != -1
        ) {
          resolve({
            data: {
              success: false,
              errMsg: "请检查网络或稍后重试",
            },
          });
          return false;
        }

        // 如果是500直接返回系统错误
        if (err.message.indexOf("500") != -1) {
          reject({
            data: {
              success: false,
              errMsg: "系统错误",
            },
          });
          return false;
        }

        reject(err);
      });
  });
};

// 创建upload的axios实例
const requestAxiosUpload = axios.create({
  baseURL: requstConfig.uploadAddress,
  timeout: 5000, // 请求超时时间
  headers: {
    token: localStorage.getItem(requstConfig.tokenStroageKey),
    languageCode: getLanguageCode(),
    "Content-Type": "multipart/form-data",
  },
});

const upload = (param: any) => {
  return requestAxiosUpload({
    url: "/file/upload",
    method: "post",
    type: "multipart/form-data",
    ...param,
    headers: {
      token: localStorage.getItem(requstConfig.tokenStroageKey),
      languageCode: getLanguageCode(),
      "Content-Type": "multipart/form-data",
      ...param.headers,
    },
  });
};

const setToken = (token: string) => {
  localStorage.setItem(requstConfig.tokenStroageKey, token);
};

const getToken = () => {
  return localStorage.getItem(requstConfig.tokenStroageKey);
};

export default {
  request,
  upload,
  setToken,
  getToken,
  getRootAddress,
  getUploadAddress,
  setLanguageCode,
};
