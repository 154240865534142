import request from "../rquest";

/**
 * 新版
 */
const visit = function (id: any) {
  const url = `${request.getUploadAddress()}/file/visit/${id}`;
  return url;
};

/**
 * 上传
 * @param groupCode 分组
 */
const getUploadPath = function (groupCode: string) {
  const url = `${request.getUploadAddress()}/file/upload/${groupCode}`;
  return url;
};

const getPreviewSrcList = function (ids: Array<any>) {
  var ids = ids || [];
  var result = [];
  for (let i = 0; i < ids.length; i++) {
    var id = ids[i] + "";
    id = id.replace("[", "").replace("]", "");
    result.push(visit(id));
  }
  console.log(result);
  return result;
};

export default {
  visit,
  getUploadPath,
  getPreviewSrcList,
};
