/**
 * 设置语言代码
 * @param languageCode
 * @constructor
 */
const setLanguageCode = (languageCode:string)=>{
    localStorage.setItem('languageCode',languageCode);
}

/**
 * 获取语言代码
 * @returns {string}
 * @constructor
 */
const getLanguageCode = ()=>{
    const loanguageCode = localStorage.getItem('languageCode') || navigator.language || "en_US";
    return loanguageCode.replace("-","_");
}

/**
 * 根据dictGroupCode获取字典
 */
const getDictMap = (dictGroupCode:string)=>{
    const languageCode = getLanguageCode();
    const dict = localStorage.getItem(`i18n:${dictGroupCode}`);
    return dict ? JSON.parse(dict) : {};
}

/**
 * 根据语言代码和dictGroupCode设置字典
 */
const setDictMap = (dictGroupCode:string,dictMap:any)=>{
    const languageCode = getLanguageCode();
    localStorage.setItem(`i18n:${dictGroupCode}`,JSON.stringify(dictMap));
}

export default {
    setLanguageCode,
    getLanguageCode,
    getDictMap,
    setDictMap
};