import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

let adminMenus = JSON.parse(localStorage.getItem("adminMenus") + "");
if (adminMenus == null) {
  adminMenus = [];
}

const routeList: {
  path: any;
  name: any;
  component: () => Promise<any>;
  meta: { title: any };
}[] = [];
adminMenus.forEach((item: any) => {
  routeList.push({
    path: item.path,
    name: item.code,
    component: () => import(`../views/${item.component}`),
    meta: {
      title: item.name,
    },
  });
});

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Body.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/body/cashier",
    name: "cashier",
    component: () => import("../views/body/cashier/Body.vue"),
    meta: {
      title: "收银",
    },
  },
  {
    path: "/body/cashier/history",
    name: "cashierHistory",
    component: () => import("../views/body/cashierHistory/Body.vue"),
    meta: {
      title: "收银历史",
    },
  },
  //库存盘点手机端
  {
    path: "/manage/goodsWarehouse/goodsWarehouseCheckByPhone",
    name: "GoodsWarehouseCheckByPhone",
    component: () =>
      import(
        "../views/manage/pages/GoodsWarehouse/GoodsWarehouseCheckByPhone/Body.vue"
      ),
    meta: {
      title: "库存盘点",
    },
  },
  //库存调拨手机端
  {
    path: "/manage/goodsWarehouse/goodsWarehouseTransferByPhone",
    name: "GoodsWarehouseTransferByPhone",
    component: () =>
      import(
        "../views/manage/pages/GoodsWarehouse/GoodsWarehouseTransferByPhone/Body.vue"
      ),
    meta: {
      title: "库存调拨",
    },
  },
  //库存调拨历史手机端
  {
    path: "/manage/goodsWarehouse/goodsWarehouseTransferHistoryByPhone",
    name: "GoodsWarehouseTransferGistoryByPhone",
    component: () =>
      import(
        "../views/manage/pages/GoodsWarehouse/GoodsWarehouseTransferHistoryByPhone/Body.vue"
      ),
    meta: {
      title: "库存调拨历史",
    },
  },
  //库存盘点历史手机端
  {
    path: "/manage/goodsWarehouse/goodsWarehouseCheckHistoryByPhone",
    name: "GoodsWarehouseCheckGistoryByPhone",
    component: () =>
      import(
        "../views/manage/pages/GoodsWarehouse/GoodsWarehouseCheckHistoryByPhone/Body.vue"
      ),
    meta: {
      title: "库存盘点历史",
    },
  },
  {
    path: "/manage",
    name: "manage",
    component: () => import("../views/manage/Body.vue"),
    meta: {
      title: "后台管理",
    },
    children: [
      {
        path: "/manage/index",
        name: "index",
        component: () => import("../views/manage/pages/Index/Index.vue"),
        meta: {
          title: "首页",
        },
      },
      // {
      //   path: "/manage/goods/goodsType",
      //   name: "GoodsType",
      //   component: () =>
      //     import("../views/manage/pages/Goods/GoodsType/Body.vue"),
      //   meta: {
      //     title: "商品分类",
      //   },
      // },
      {
        path: "/manage/goods/goods",
        name: "Goods",
        component: () => import("../views/manage/pages/Goods/Goods/Body.vue"),
        meta: {
          title: "商品",
        },
      },
      {
        path: "/manage/goodsStore",
        name: "GoodsStore",
        component: () => import("../views/manage/pages/GoodsStore/Body.vue"),
        meta: {
          title: "门店与店员",
        },
      },
      {
        path: "/manage/goodsWarehouse/goodsWarehouse",
        name: "GoodsWarehouse",
        component: () =>
          import(
            "../views/manage/pages/GoodsWarehouse/GoodsWarehouse/Body.vue"
          ),
        meta: {
          title: "仓库管理",
        },
      },
      {
        path: "/manage/goodsWarehouse/goodsWarehouseSearch",
        name: "GoodsWarehouseSearch",
        component: () =>
          import(
            "../views/manage/pages/GoodsWarehouse/GoodsWarehouseSearch/Body.vue"
          ),
        meta: {
          title: "库存查询",
        },
      },
      {
        path: "/manage/goodsWarehouse/goodsWarehouseDetail",
        name: "GoodsWarehouseDetail",
        component: () =>
          import(
            "../views/manage/pages/GoodsWarehouse/GoodsWarehouseDetail/Body.vue"
          ),
        meta: {
          title: "仓库流水",
        },
      },
      //库存盘点
      {
        path: "/manage/goodsWarehouse/goodsWarehouseCheck",
        name: "GoodsWarehouseCheck",
        component: () =>
          import(
            "../views/manage/pages/GoodsWarehouse/GoodsWarehouseCheck/Body.vue"
          ),
        meta: {
          title: "库存盘点",
        },
      },
      //库存调拨
      {
        path: "/manage/goodsWarehouse/goodsWarehouseTransfer",
        name: "GoodsWarehouseTransfer",
        component: () =>
          import(
            "../views/manage/pages/GoodsWarehouse/GoodsWarehouseTransfer/Body.vue"
          ),
        meta: {
          title: "库存调拨",
        },
      },
      // GoodsProcureOrder
      {
        path: "/manage/GoodsProcure/goodsProcureOrder",
        name: "GoodsProcureOrder",
        component: () =>
          import(
            "../views/manage/pages/GoodsProcure/GoodsProcureOrder/Body.vue"
          ),
        meta: {
          title: "商品采购单",
        },
      },
      //商品采购退货单
      {
        path: "/manage/GoodsProcure/goodsProcureRetutnOrder",
        name: "GoodsProcureRetutnOrder",
        component: () =>
          import(
            "../views/manage/pages/GoodsProcure/GoodsProcureReturnOrder/Body.vue"
          ),
        meta: {
          title: "采购退货单",
        },
      },
      //打印模板
      {
        path: "/manage/templatePrint",
        name: "TemplatePrint",
        component: () => import("../views/manage/pages/TemplatePrint/Body.vue"),
        meta: {
          title: "打印模板",
        },
      },
      // 角色
      {
        path: "/manage/role",
        name: "Role",
        component: () => import("../views/manage/pages/Role/Body.vue"),
        meta: {
          title: "角色",
        },
      },
      //销售单
      {
        path: "/manage/sale/saleOrder",
        name: "SaleOrder",
        component: () =>
          import("../views/manage/pages/Sales/SalesOrder/Body.vue"),
        meta: {
          title: "销售单",
        },
      },
      //系统设置
      {
        path: "/manage/setting",
        name: "Setting",
        component: () => import("../views/manage/pages/Setting/Body.vue"),
        meta: {
          title: "系统设置",
        },
      },
      //账本
      {
        path: "/manage/accountBook",
        name: "AccountBook",
        component: () => import("../views/manage/pages/AccountBook/Body.vue"),
        meta: {
          title: "账本",
        },
      },
      //工资
      {
        path: "/manage/salary",
        name: "Salary",
        component: () => import("../views/manage/pages/Salary/Body.vue"),
        meta: {
          title: "工资",
        },
      },
      //销售报表
      {
        path: "/manage/report/saleReport",
        name: "SaleReport",
        component: () =>
          import("../views/manage/pages/ReportForm/SalesReport/Body.vue"),
        meta: {
          title: "销售报表",
        },
      },
      //调拨报表
      {
        path: "/manage/report/transferReport",
        name: "TransferReport",
        component: () =>
          import("../views/manage/pages/ReportForm/TransferReport/Body.vue"),
        meta: {
          title: "调拨报表",
        },
      },
      //盘点报表
      {
        path: "/manage/report/checkReport",
        name: "CheckReport",
        component: () =>
          import("../views/manage/pages/ReportForm/CheckReport/Body.vue"),
        meta: {
          title: "盘点报表",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // 先设置标题为“加载中...”
  document.title = "加载中...";
  document.title = to.meta.title + "";
  next();
});

export default router;
